<template>
  <v-col
    v-if="value"
    cols="12"
    :md="size === 2 ? 6 : size === 3 ? 4 : undefined"
  >
    <!-- :height="value.prominent ? 450 : 350" -->
    <base-card
      height="400"
      color="grey lighten-1"
      dark
      @click="navigationHandler"
    >
      <!-- :to="
        tag_id == 2
          ? value.link
          : `post-details/${value.post_id ? value.post_id.id : value.id}?type=${
              type == 'post' ? 1 : 2
            }&tag_id=${tag_id}`
      " -->
      <!-- require(`@/assets/articles/moroccandays.jpg`) -->
      <!-- :src="
          value.featured_image
            ? value.featured_image.data.full_url
            : value.post_id && value.post_id.featured_image
            ? value.post_id.featured_image.data.full_url
            : null
        " -->
      <v-img
        :src="value.featured_image && value.featured_image.data.full_url"
        height="100%"
        gradient="rgba(0, 0, 0, .42), rgba(0, 0, 0, .42)"
        style="objectFit:contain"
      >
        <v-row v-if="!value.prominent" class="fill-height text-right ma-0">
          <v-col cols="12">
            <!-- <v-chip
              label
              class="mx-0 mb-2 text-uppercase"
              color="grey darken-3"
              text-color="white"
              small
              @click.stop=""
            >
              {{
                value.cat_id.length
                  ? value.cat_id[0].category_id.title
                  : 'general'
              }}
            </v-chip> -->

            <h3 class="title font-weight-bold mb-2 white--text">
              {{ value[lang + 'title'] }}
            </h3>

            <div
              v-if="value.author_id && type == 'post'"
              class="caption white--text"
            >
              {{ value.author_id.first_name + ' ' + value.author_id.last_name }}
            </div>
            <div v-if="tag_id == 1" class="caption white--text">
              {{ value.date }}
            </div>
          </v-col>

          <v-col align-self="end">
            <v-chip
              class="text-uppercase ma-0"
              color="primary"
              label
              small
              @click.stop=""
            >
              {{ $t('more') }}
            </v-chip>
          </v-col>
        </v-row>
      </v-img>
    </base-card>
  </v-col>
</template>

<script>
export default {
  name: 'PostFeedCard',

  props: {
    size: {
      type: Number,
      required: true,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: 'post',
    },
    tag_id: {
      type: Number,
    },
  },
  data() {
    return {
      lang: this.$root.lang == 'en' ? 'en' : '',
      // itemValue: null,
    };
  },
  methods: {
    navigationHandler() {
      if (this.tag_id == 2) {
        window.location.assign(this.value.link, '_blank');
      } else {
        let url = `post-details/${
          this.value.post_id ? this.value.post_id.id : this.value.id
        }?type=${this.type == 'post' ? 1 : 2}&tag_id=${this.tag_id}`;
        this.$router.push(url);
      }
      //  tag_id == 2
      //     ? value.link
      //     : `post-details/${value.post_id ? value.post_id.id : value.id}?type=${
      //         type == 'post' ? 1 : 2
      //       }&tag_id=${tag_id}`
    },
  },
};
</script>

<style>
.v-image__image {
  transition: 0.3s linear;
}
</style>
